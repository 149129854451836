<template>
	<v-container>
		<v-form ref="dform" v-model="isValid">
			<v-row>
				<v-col>
					<v-text-field
						v-model="FirstName"
						label="First Name"
						hint="What is your first name?"
						:rules="rules.required"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="LastName"
						label="Last Name"
						hint="What is your last name?"
						:rules="rules.required"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="email"
						label="Email Address"
						hint="What is your email address?"
						:rules="rules.emailRules"
						:error-messages="emailExists ? ['Email already in use'] : []"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						prepend-icon="+1"
						:rules="[...rules.required, rules.min10]"
						v-mask="`(###) ###-####`"
						v-model="phone"
						label="Phone Number"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="password"
						:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[...rules.required, ...rules.min]"
						:type="show1 ? 'text' : 'password'"
						label="Password"
						hint="At least 8 characters"
						autocomplete="new-password"
						counter
						@click:append="show1 = !show1"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="password1"
						:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[
							...rules.mustMatch,
							...rules.required,
							...rules.min,
							...rules.match,
						]"
						:type="show1 ? 'text' : 'password'"
						label="Confirm Password"
						hint="At least 8 characters"
						autocomplete="new-password"
						counter
						@click:append="show1 = !show1"
					/>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	props: {
		value: {
			default: null,
		},
	},
	data() {
		return {
			FirstName: "",
			LastName: "",
			email: "",
			phone: "",
			password: "",
			password1: "",
			show1: false,
			isValid: false,
			emailExists: false,
			rules: {
				required: [(v) => !!v || "Required"],
				min: [(v) => v.length >= 8 || "Min 8 characters"],
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
				],
				mustMatch: [
					(value) =>
						value === this.password.substring(0, value.length + 1) ||
						"Passwords must match",
				],
				min10: (value) =>
					(value || "").length >= 14 || "10 digit phone number is required",
			},
		};
	},
	methods: {
		async checkEmail(v) {
			let valid = /.+@.+\..+/.test(v);
			if (!valid) return false;
			try {
				let data = await axios.get(`${this.$ServerURI}/email/exists/${v}`);
				data = data.data;
				console.log(data);
				return data.exists;
			} catch (error) {
				console.log(error);
				if (error.response) {
					console.log(error.response);
					console.log(error.response.body);
				}
				return false;
			}
		},
	},
	mounted() {
		if (!this.value) return;
		for (const key in this.value) {
			this.$data[key] = this.value[key];
		}
	},
	watch: {
		email(v) {
			console.log("checking");
			this.checkEmail(v).then((r) => {
				this.emailExists = r;
				console.log(r);
			});
		},
		isValid(v) {
			let valid = v && !this.emailExists;
			this.$emit("isValid", valid);
			if (!valid) return;
			this.$emit("input", {
				FirstName: this.FirstName,
				LastName: this.LastName,
				email: this.email,
				phone: this.phone,
				password: this.password,
			});
		},
	},
};
</script>

<style></style>
