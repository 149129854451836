<template>
	<div>
		<div v-if="loaded">
			<v-card v-if="!activeInvite">
				<v-card-title>Invite doesn't exist!</v-card-title>
				<v-card-text>
					The invite does not exist! The invite could already be accepted, or
					has been revoked.
					<center>
						<v-btn to="/" class="mt-10" color="primary">
							<v-icon>
								mdi-home
							</v-icon>
							Go Home
						</v-btn>
					</center>
				</v-card-text>
			</v-card>
			<v-card v-else>
				<v-card-title>Welcome to TicketVM</v-card-title>
				<v-card-text v-if="!accepted">
					<user-registration @input="updateUser" @isValid="updateIsValid" />
				</v-card-text>
				<v-expand-transition>
					<v-card-text v-if="accepted">
						<center>
							<v-icon color="green" size="96">
								mdi-check-circle
							</v-icon>
							<h1>Welcome! Your account has been created.</h1>
						</center>
					</v-card-text>
				</v-expand-transition>
				<v-card-actions v-if="!accepted">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="!isValid"
						:loading="saving"
						color="primary"
						@click="acceptInvite"
					>
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</div>

		<v-skeleton-loader type="card" :loading="true" v-else />
		<alert v-model="showErrorMessage" Header="Oh no!">
			An error has occured
		</alert>
	</div>
</template>

<script>
import axios from "axios";
import Alert from "@/components/ModalMessages/Alert.vue";
import userRegistration from "@/components/CreateAccount/userRegistration.vue";
export default {
	components: { userRegistration, Alert },

	data() {
		return {
			loaded: false,
			activeInvite: true,
			user: {
				FirstName: "",
				LastName: "",
				email: "",
				phone: "",
				password: "",
			},
			isValid: false,
			saving: false,
			accepted: false,
			showErrorMessage: false,
		};
	},
	mounted() {
		axios
			.get(
				`https://api.ticketvm.com/organization/${this.$route.params.orgid}/invites/exists/${this.$route.params.acceptID}`
			)
			.then(({ data }) => {
				this.activeInvite = data.active && data.found;
				this.loaded = true;
			});
	},
	methods: {
		updateUser(data) {
			this.user = data;
		},
		updateIsValid(v) {
			this.isValid = v;
		},
		acceptInvite() {
			this.saving = true;
			axios
				.post(
					`https://api.ticketvm.com/organization/${this.$route.params.orgid}/invites/accept/${this.$route.params.acceptID}`,
					{
						user: this.user,
					}
				)
				.then(({ data }) => {
					this.saving = false;
					if (data.accepted) {
						this.accepted = true;
					} else {
						this.accepted = false;
					}

					if (this.accepted) {
						setTimeout(() => {
							this.$router.push("/login");
						}, 5 * 1000);
					}
				})
				.catch((err) => {
					this.showErrorMessage = true;
					console.log(err);
					if (err.response) console.log(err.response);
				});
		},
	},
};
</script>

<style></style>
